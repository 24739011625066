<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入标题" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.goods_type" clearable placeholder="请选择商品类型" size="small">
          <el-option :key="1" label="普通商品" :value="1"></el-option>
          <el-option :key="2" label="虚拟商品" :value="2"></el-option>
          <el-option :key="3" label="卡密商品" :value="3"></el-option>
          <el-option :key="4" label="优惠券" :value="4"></el-option>
          <el-option :key="5" label="会员卡" :value="5"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.menu" clearable placeholder="请选择分类" size="small">
          <el-option
              v-for="item in menuList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.status" clearable placeholder="请选择上架状态" size="small">
          <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="mg-bt-10">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="addGroup()">添加</el-button>
      <el-button type="danger" size="small" icon="el-icon-delete" @click="delGroup()">删除</el-button>
    </div>
    <el-table
        :data="tableData"
        ref="rfTable"
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="title"
          label="标题"
          min-width="200">
        <template slot-scope="scope">
          <span class="red" v-if="scope.row.marking && scope.row.marking.marking_type==1">[预售]</span>
          <span class="red" v-if="scope.row.marking && scope.row.marking.marking_type==2">[拼团]</span>
          <span class="red" v-if="scope.row.marking && scope.row.marking.marking_type==3">[秒杀]</span>
          <span class="red" v-if="scope.row.marking && scope.row.marking.marking_type==4">[砍价]</span>
          {{ scope.row.title }}
        </template>
      </el-table-column>
      <el-table-column
          prop="goods_type_str"
          label="类型"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="menu_ids_str"
          label="分类"
          align="center"
          width="200">
      </el-table-column>
      <el-table-column
          prop="imgs"
          label="图片"
          align="center"
          width="120">
        <template slot-scope="scope">
          <el-avatar shape="square" :size="60" :src="scope.row.img"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column
          prop="price"
          label="价格"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="sales"
          label="销量"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="stock"
          label="库存"
          align="center"
          width="100">
        <template slot-scope="scope">
          <div style="cursor: pointer;" @click="showStock(scope.row.id)">
            {{scope.row.stock}}
            <i class="el-icon-edit-outline"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="status"
          label="上架状态"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-switch :value="scope.row.is_onsale==1" @change="changeSale(scope.row.id,scope.row.is_onsale==1?0:1)" active-text="下架" inactive-text="上架">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
          prop="recommend_time"
          label="推荐状态"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-switch :value="scope.row.recommend_time>0" @change="changeRecommend(scope.row.id,scope.row.recommend_time>0?0:1)" active-text="推荐" inactive-text="取消">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column v-if="functionList.guider==1"
          prop="is_guider"
          label="推客推广"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-switch :value="scope.row.is_guider==1" @change="changeGuider(scope.row.id,scope.row.is_guider==1?0:1)" active-text="关闭" inactive-text="参与">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
          prop="ordering"
          label="排序"
          align="center"
          width="80">
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="添加时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editGroup(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog
        title="修改库存"
        :visible.sync="showModal"
        :destroy-on-close="true"
        width="700px">
      <change-stock :goodsId="goodsId" @callFunc="changeSuccess()"></change-stock>
    </el-dialog>
  </div>
</template>
<script>

import ChangeStock from "@/components/Goods/ChangeStock";
export default {
  components: {
    ChangeStock
  },
  data() {
    return {
      info: false,
      showModal: false,
      goodsId: 0,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        status: '',
        menu: '',
        goods_type: ''
      },
      tableData: [],
      isOpen: false,
      statusList: [
        {id:1,title:'上架'},
        {id:2,title:'下架'},
      ],
      menuList: [],
      functionList: {
        guider: 0
      }
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisiable);
  },
  computed: {},
  methods: {
    initData() {
      this.getFuncList()
      this.getList();
      this.getMenuList()
    },
    handleVisiable(e) {
      if(this.isOpen && e.target.visibilityState === 'visible') {
        this.isOpen = false
        this.initData()
      }
    },
    getFuncList() {
      this.$api.merchant.sysFunctionList({keys:'guider'},res=>{
        if(res.errcode==0) {
          this.functionList = res.data
        }
      })
    },
    getMenuList() {
      this.$api.goods.menuAll({}, res => {
        if (res.errcode == 0) {
          this.menuList = res.data
        }
      })
    },
    changeSuccess() {
      this.showModal = false
      this.goodsId = 0
      this.getList();
    },
    showStock(id) {
      this.showModal = true
      this.goodsId = id
    },
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        status: that.search.status,
        menu: that.search.menu,
        goods_type: that.search.goods_type
      }
      this.$api.goods.goodsIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    addGroup() {
      this.isOpen = true
      window.open('/goods/add')
      // this.$router.push({name: 'goods_add'})
    },
    editGroup(item) {
      this.isOpen = true
      window.open('/goods/add?id=' + item.id)
      // this.$router.push({name: 'goods_add', query: {id: item.id}})
    },
    changeSale(id,status) {
      this.$api.goods.goodsShenhe({id: id, is_onsale: status}, res => {
        if (res.errcode == 0) {
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    changeRecommend(id,status) {
      this.$api.goods.goodsRecommend({id: id, status: status}, res => {
        if (res.errcode == 0) {
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    changeGuider(id,status) {
      this.$api.goods.goodsGuider({id: id, status: status}, res => {
        if (res.errcode == 0) {
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    changeRec(id,status) {
      this.$api.goods.goodsRec({id: id, is_rec: status}, res => {
        if (res.errcode == 0) {
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    delGroup() {
      var that = this
      var sel = this.$refs.rfTable.selection
      if (sel.length < 1) {
        that.tips('请选择删除项')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.$confirm('确认要删除这些数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.goods.goodsDel({ids: ids}, function (res) {
          if (res.errcode == 0) {
            that.success('删除成功')
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
