<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="80px" size="small">
      <el-form-item label="" label-width="0">
        <el-radio-group v-model="type">
          <el-radio :label="1">增加库存</el-radio>
          <el-radio :label="2">减少库存</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-table
          :data="tableData"
          ref="rfTable"
          style="width: 100%;"
          size="mini">
        <el-table-column v-if="info.is_sku==1"
            prop="title"
            label="规格">
        </el-table-column>
        <el-table-column
            prop="stock"
            label="当前库存">
        </el-table-column>
        <el-table-column
            prop="value"
            label="修改库存">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.value" placeholder="请输入数量" size="small" :min="0" :max="1000000"></el-input-number>
          </template>
        </el-table-column>
      </el-table>
      <el-form-item label-width="0" class="mg-tp-20">
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

export default {
  data() {
    return {
      type: 1,
      info: {
        id: 0,
      },
      tableData: [],
      rules: {
      },
      isSub: false
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  props: {
    goodsId: Number,
    callFunc: Function
  },
  watch: {
    'goodsId': {
      handler (newValue, oldValue) {
        this.info.id = newValue
        this.getGoodsInfo()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getGoodsInfo() {
      if(this.info.id==0) return false
      this.tableData = []
      this.$api.goods.goodsInfo({id: this.info.id}, res => {
        if(res.errcode==0) {
          this.info = res.data
          if(this.info.is_sku==1) {
            this.info.specsList.forEach(item => {
              this.tableData.push({
                id: item.id,
                title: item.title,
                stock: item.stock,
                value: ''
              })
            })
          } else {
            this.tableData.push({
              stock: this.info.stock,
              value: ''
            })
          }
        }
      })
    },
    submitForm(formName) {
      var _this = this
      if(this.isSub) return false
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          var params = {
            goods_id: this.info.id,
            type: this.type,
          }
          if(this.info.is_sku==1) {
            var speList = []
            this.tableData.forEach(item=> {
              speList.push({
                id: item.id,
                stock: item.value
              })
            })
            params.spec = speList;
          } else {
            params.stock = this.tableData[0].value
          }
          this.isSub = true
          this.$api.goods.changeStock(params,function (res) {
            if(res.errcode == 0) {
              _this.success(res.errmsg)
              _this.$emit('callFunc')
            } else {
              _this.isSub = false
              _this.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
</style>
